var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-spin',{attrs:{"tip":_vm.spinnerLoaderLabel,"size":"large","spinning":_vm.spinnerLoader}},[_c('div',{staticClass:"card",class:_vm.$style.container},[_c('div',{staticClass:"text-dark font-size-24 mb-4"},[_c('strong',[_vm._v("Recuperación de contraseña")])]),_c('a-form',{staticClass:"mb-4",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'email',
							{
								rules: [
									{ required: true, message: 'Ingrese su correo electrónico' },
									{ email: true, message: 'Ingrese correo electrónico válido' },
								],
							},
						]),expression:"[\n\t\t\t\t\t\t\t'email',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{ required: true, message: 'Ingrese su correo electrónico' },\n\t\t\t\t\t\t\t\t\t{ email: true, message: 'Ingrese correo electrónico válido' },\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],attrs:{"size":"large","placeholder":"Email registrado"}})],1),_c('a-button',{staticClass:"text-center w-100",attrs:{"size":"large","type":"primary","htmlType":"submit"}},[_c('strong',[_vm._v("Enviar")])])],1),_c('router-link',{staticClass:"kit__utils__link font-size-16",attrs:{"to":"/auth/login"}},[_c('i',{staticClass:"fe fe-arrow-left mr-1 align-middle"}),_vm._v(" Regresar a inicio de sesión ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }