<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card" :class="$style.container">
				<div class="text-dark font-size-24 mb-4">
					<strong>Recuperación de contraseña</strong>
				</div>
				<a-form class="mb-4" :form="form" @submit="handleSubmit">
					<a-form-item>
						<a-input
							size="large"
							placeholder="Email registrado"
							v-decorator="[
								'email',
								{
									rules: [
										{ required: true, message: 'Ingrese su correo electrónico' },
										{ email: true, message: 'Ingrese correo electrónico válido' },
									],
								},
							]"
						/>
					</a-form-item>
					<a-button size="large" type="primary" htmlType="submit" class="text-center w-100">
						<strong>Enviar</strong>
					</a-button>
				</a-form>
				<router-link to="/auth/login" class="kit__utils__link font-size-16">
					<i class="fe fe-arrow-left mr-1 align-middle" />
					Regresar a inicio de sesión
				</router-link>
			</div>
		</a-spin>
	</div>
</template>
<script>
export default {
	name: 'CuiForgotPassword',
	computed: {
		spinnerLoader() {
			return this.$store.state.user.loading
		},
		spinnerLoaderLabel() {
			return this.$store.state.user.spinnerLoaderLabel || 'Espere por favor...'
		},
	},
	data: function () {
		return {
			form: this.$form.createForm(this),
		}
	},
	methods: {
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (!err) {
					this.$store.dispatch('user/RECOVERY_PASSWORD', { payload: values })
				}
			})
		},
	},
}
</script>
<style lang="scss" module>
@import '@/components/cleanui/system/Auth/style.module.scss';
</style>
